import React from 'react';
import { rgba, tint, shade } from 'polished';
import styled from 'styled-components'
import * as Clrs from '../../src/js/colors'
import {CSSTransition} from 'react-transition-group';



const DimmerBox = styled.div`
    display: flex;
    background-color: ${Clrs.black};
    opacity: 0.8;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    &.fade-enter {
        opacity: 0.01;
    }
    &.fade-enter-active {
        opacity: 0.8;
        transition: opacity 250ms ease-out;
    }
    &.fade-exit {
        opacity: 0.8;
    }
    &.fade-exit-active {
        opacity: 0.01;
        transition: opacity 250ms ease-out;
    }
`
const ModalContentContainer = styled.div`
    display: ${props => props.show ? "flex" : "none"};
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 300;
    width: 100%;
`

const ModalHeaderBlock = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    height: 90px;
    box-sizing: border-box;
    background-color: ${Clrs.black};
`

const CloseModal = styled.div`
    margin-left: auto;
    color: ${Clrs.lightgrey};
    z-index: 300;
    display: flex;
    width: 80px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Clrs.white};
    &:hover{
        background-color: ${Clrs.pink};
        cursor: pointer;
        color: ${Clrs.white};
    }
`
const PaintingDesc = styled.div`
    color: ${Clrs.white};
    font-size: 12px;
    width: 100%;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
`


const ViewerContainer = styled.div`
    width: 100%;
    height: calc(100vh - 90px);
    align-items: center;
    justify-content: center;
    z-index: 200;
    display: flex;
    img {
        object-fit: contain;
        max-width: 80%;
        
        max-height: 80%;
        display: inline-block;
        border: 10px solid ${Clrs.white};
        transform: scale(1,1);
        &.pop-enter {
            transform: scale(0.7, 0.7);
        }
        &.pop-enter-active {
            transform: scale(1,1);
            transition transform 300ms cubic-bezier(.66,.01,.22,1.17);
        }
        &.pop-exit {
            transform: scale(0.7, 0.7);
        }
        &.pop-exit-active {
            transform: scale(1,1);
            transition transform 300ms ease-in;
        }
    }
`

const Viewer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex: 0 1 auto;
    z-index: 200;
    align-items: center;
    justify-content: center;
    
    
`


function Modal(props) {
    console.log(props.desc)

    return (
        <>
            <CSSTransition
                in={props.in}
                timeout={250}
                classNames="fade"
                unmountOnExit
            >
                <DimmerBox />
            </CSSTransition>
            
            <ModalContentContainer show={props.in}>
                <ModalHeaderBlock>
                    <PaintingDesc>
                        {props.desc}
                    </PaintingDesc>
                    {props.closebtn}
                </ModalHeaderBlock>
                <ViewerContainer>
                    <CSSTransition
                        in={props.in}
                        timeout={250}
                        classNames="pop"
                        unmountOnExit
                    >
                        {props.children}
                    </CSSTransition>
                </ViewerContainer>
            </ModalContentContainer>
            
                
        </>
      
    );
  }


  export {Modal, CloseModal};