import React, {useState} from 'react';
import { rgba, tint, shade } from 'polished';
import styled from 'styled-components'
import * as Clrs from '../../src/js/colors'
import {Painting, Year} from '../components/painting';
import {Modal, CloseModal} from '../components/modal';

import o1 from '../images/crow.jpg';
import o2 from '../images/emilien.jpg';
import o3 from '../images/william.jpg';
import o4 from '../images/crabapple.jpg';


function OtherWork(props) {
    const [showModal, setShowModal] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [descriptionText, setDescriptionText] = useState("");
    function closeModal(){
      setShowModal(false);
    }
    function openModal(img) {
      setShowModal(true);
      setSelectedImg(img);
    }
    return (
        <>
        <Year yr="Ink">
        <Painting txt="The Old Crabapple" narrow onClick={()=>{openModal(o4); setDescriptionText("The Old Crabapple || Ink on paper || dimensions"); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={o4} />
        </Painting>
        <Painting txt="Auspex (Crowna Lisa)" narrow onClick={()=>{openModal(o1); setDescriptionText("Auspex (Crowna Lisa) || Ink on paper || dimensions"); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={o1} />
        </Painting>
        <Painting txt="Emilien"  onClick={()=>{openModal(o2); setDescriptionText(`Emilien || Scratchboard || 12"x12"`); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={o2} />
        </Painting>
        <Painting txt="William"  onClick={()=>{openModal(o3); setDescriptionText(`William || Scratchboard || 12"x12"`); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={o3} />
        </Painting>
      </Year>
       
      <Modal in={showModal}  desc={descriptionText} closebtn={<CloseModal onClick={()=>setShowModal(false)}>Close</CloseModal>}>
        <img src={selectedImg} />
      </Modal>
      </>
    );
  }



  export {OtherWork};