import React, {useState} from 'react';
//import logo from './logo.svg';
import { rgba, tint, shade } from 'polished';
import styled from 'styled-components';
import {BrowserRouter, Link, Switch, Route} from 'react-router-dom'
import {Paintings} from './pages/paintings'
import {OtherWork} from './pages/otherwork'

import './reset.css';
import './global.css';
import * as Clrs from './js/colors';

const Header = styled.div`
  display: flex;
  background-color: ${Clrs.black};
  height: 70px;
  color: ${Clrs.lightblue};
  align-items: center;
  border-bottom: 1px solid ${Clrs.lightgrey};
  box-sizing: border-box;
  z-index: 10;
  h1 { 
    margin-left: 30px;
    font-size: 20px; 
    font-weight: 700;
  
  }
`

const HeaderNavContainer = styled.div`
  margin-left: auto;
  margin-right: 30px;
  display: flex;
  color: ${Clrs.white};
  font-size: 16px;
  a {
    color: ${Clrs.white};
    text-decoration: none;
    margin-left: 20px;
    &:hover {
      color: ${Clrs.lightblue};
    }
  }
`

const MainContentArea = styled.div`
  height: calc(100vh - 70px);
  background-color: ${Clrs.darkgrey};
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
  z-index: 20;
`



function App() {
  
  
  return (
    <>
    <BrowserRouter>
    <Header>
      <h1>JPFive.com</h1>
      <HeaderNavContainer>
      
        <Link to="/paintings">paintings</Link> 
        <Link to="/otherwork">other works (ink, etc.)</Link> 
        <Link to="/about" >about</Link>   
        <Link to="/contact">contact</Link>  
         
      </HeaderNavContainer>  
    </Header>
    
    
    
    <MainContentArea>
    <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/otherwork">
          <OtherWork />
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
        <Route path="/paintings">
          <Paintings/>
        </Route>
        <Route path="/">
          <Paintings/>
        </Route>
      </Switch>
      
    </MainContentArea>
      
      </BrowserRouter>
    </>
  );
}

export default App;

function About() {
    return(<p>test about</p>)
}

/* function OtherWork() {
    return(<p>test other</p>)
}
 */
function Contact() {
    return(<p>test contact</p>)
}