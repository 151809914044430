import React from 'react';
import { rgba, tint, shade } from 'polished';
import styled from 'styled-components'
import * as Clrs from '../../src/js/colors'

const YearContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: flex-start;
    margin-bottom: 16px;
`
const PaintingBlock = styled.div`
    display: flex; 
    flex-wrap: wrap;
    border: 1px solid ${Clrs.grey};
    
    background-color: rgba(0,0,0,0.4);
    flex: 0 0 auto;
`

const YearText = styled.div`    
    background-color: ${Clrs.pink};
    padding: 4px;
    font-size: 11px;
    color: ${Clrs.white};
    border-radius: 4px 4px 0 0;
    font-weight: 700;
    flex: 0 0 auto;
    margin-left: 8px;
    
`

const ThumbContainer = styled.div`
    border: 3px solid ${Clrs.light};
    height: 200px;
    min-width: 150px;
    display: flex;
    position: relative;
    margin: 8px;
    cursor: pointer;
    width: ${props => props.narrow ? "150px" : props.medium ? "200px" : props.wide ? "300px" : "200px"};
    img {
        max-width: 100%;
        object-fit: cover;
    }
    &:hover {
        div {height: 100%;}
    }
`

const PaintingOverlay = styled.div`

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  
`

const Title = styled.p`
    color: ${Clrs.lightgreen};
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
`

const SoldBadge = styled.div`
    position: absolute;
    right: 0%;
    bottom: 0%;
    background-color: ${Clrs.lightblue};
    color: white;
    padding: 6px;
    font-size: 12px;
`

function Year(props) {
    return (
      <YearContainer>
          <YearText>{props.yr}</YearText>
          <PaintingBlock>
            
            {props.children}
          </PaintingBlock>
      </YearContainer>
    );
  }

function Painting(props) {
    return (
      <ThumbContainer onClick={props.onClick} narrow={props.narrow} medium={props.medium} wide={props.wide}>
          {props.children}
          <PaintingOverlay>
              <Title>{props.txt}</Title>
          </PaintingOverlay>
          {props.flag && <SoldBadge>{props.flag}</SoldBadge>}
            
          
      </ThumbContainer>
    );
  }

  export {Painting, Year};