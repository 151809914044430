import React, {useState} from 'react';
import { rgba, tint, shade } from 'polished';
import styled from 'styled-components'
import * as Clrs from '../../src/js/colors'
import {Painting, Year} from '../components/painting';
import {Modal, CloseModal} from '../components/modal';

import p1 from '../images/anUnmarkedPlace.jpg';
import p2 from '../images/thisTooShallPass.jpg';
import p3 from '../images/weatherWorry.jpg';
import p4 from '../images/theRoadToNowhere.jpg';
import p5 from '../images/height.jpg';
import p6 from '../images/coldQuietDark.jpg';
import p7 from '../images/beforeFalling.jpg';
import p8 from '../images/contractOfLand.jpg';
import p9 from '../images/theyFlutterBehindYou.jpg';
import p10 from '../images/pivots.jpg';
import p11 from '../images/rocks.jpg';
import p12 from '../images/alteredLandscape.jpg';
import p13 from '../images/falling.jpg';
import p14 from '../images/grove.jpg';
import p15 from '../images/masTequila.jpg';
import p16 from '../images/sunflower.jpg';
import p17 from '../images/sunflowers.jpg';
import p18 from '../images/itsSuchADrag.jpg';
import p19 from '../images/nowayout.jpg';
import p20 from '../images/oldhead.jpg';
import p21 from '../images/theGreenJacket.jpg';
import p22 from '../images/fjordOfSorts.jpg';
import p23 from '../images/Somewhere-Up-North.jpg';
import p24 from '../images/untitled2.jpg';
import p25 from '../images/theIowa.jpg';
import p26 from '../images/natureswall.jpg';
import p27 from '../images/natureswall2.jpg';


function Paintings(props) {
    const [showModal, setShowModal] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [descriptionText, setDescriptionText] = useState("");
    function closeModal(){
      setShowModal(false);
    }
    function openModal(img) {
      setShowModal(true);
      setSelectedImg(img);
    }
    return (
        <>
        <Year yr="2021">
        <Painting txt="The Iowa" onClick={()=>{openModal(p25); setDescriptionText("The Iowa || Oil on canvas board || dimensions"); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={p25} />
        </Painting>
        <Painting txt="Nature's Wall #1" wide onClick={()=>{openModal(p26); setDescriptionText(`Nature's Wall #1 || Oil on canvas board || 36" x 18" `); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={p26} />
        </Painting>
        <Painting txt="Nature's Wall #2" wide onClick={()=>{openModal(p27); setDescriptionText(`Nature's Wall #2 || Oil on canvas board || 36" x 12" `); }} closeFunc={()=>setShowModal(false)}>
          <img alt="txt" src={p27} />
        </Painting>
        
      </Year>
      <Year yr="2019">
        <Painting txt="Sentinel" narrow onClick={()=>{openModal(p16); setDescriptionText("Sentinel || Oil on canvas board || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p16} /></Painting>
        <Painting txt="A Solemn Gathering" narrow onClick={()=>{openModal(p17); setDescriptionText("A Solemn Gathering || Oil on panel || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p17} /></Painting>
        <Painting txt="18 at Oldhead" flag="sold" onClick={()=>{openModal(p20); setDescriptionText("18 at Oldhead || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p20} /></Painting>
        <Painting txt="The Green Jacket" narrow onClick={()=>{openModal(p21); setDescriptionText("The Green Jacket || Oil on canvas board || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p21} /></Painting>
        
      </Year>
      <Year yr="2018">
        <Painting txt="This Too Shall Pass" wide onClick={()=>{openModal(p2); setDescriptionText("This Too Shall Pass || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p2} /></Painting>
        <Painting txt="They Flutter Behind You" wide onClick={()=>{openModal(p9); setDescriptionText("They Flutter Behind You || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p9} /></Painting>
        <Painting txt="Somewhere Up North" wide onClick={()=>{openModal(p23); setDescriptionText("Somewhere Up North || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p23} /></Painting>
      </Year>
      <Year yr="2017">
        <Painting txt="An Unmarked Place" onClick={()=>{openModal(p1); setDescriptionText("An Unmarked Place || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p1} /></Painting>
        <Painting txt="Weather; Worry" flag="sold" onClick={()=>{openModal(p3); setDescriptionText("Weather; Worry || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p3} /></Painting>
        <Painting txt="The Road to Nowhere" wide flag="private collection" onClick={()=>{openModal(p4); setDescriptionText("The Road to Nowhere || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p4} /></Painting>
        <Painting txt="It's Such a Drag" onClick={()=>{openModal(p18); setDescriptionText("It's Such a Drag || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p18} /></Painting>
        <Painting txt="Untitled" narrow onClick={()=>{openModal(p24); setDescriptionText("Untitled || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p24} /></Painting>
        <Painting txt="There's No Way Out of Here" onClick={()=>{openModal(p19); setDescriptionText("There's No Way Out of Here || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p19} /></Painting>
      </Year>
      <Year yr="2016">
        <Painting txt="Height" onClick={()=>{openModal(p5); setDescriptionText("Height || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p5} /></Painting>
        <Painting txt="Cold, Quiet & Dark" onClick={()=>{openModal(p6); setDescriptionText("Cold, Quiet & Dark || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p6} /></Painting>
        <Painting txt="Before Falling" wide onClick={()=>{openModal(p7); setDescriptionText("Before Falling || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p7} /></Painting>
        <Painting txt="A Contract of Land" flag="sold" onClick={()=>{openModal(p8); setDescriptionText("A Contract of Land || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p8} /></Painting>
        <Painting txt="Pivots" flag="sold" onClick={()=>{openModal(p10); setDescriptionText("Pivots || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p10} /></Painting>
        <Painting txt="The Rocks Slowed Our Descent" wide onClick={()=>{openModal(p11); setDescriptionText("The Rocks Slowed Our Descent || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p11} /></Painting>
        <Painting txt="Altered Landscape" wide flag="sold" onClick={()=>{openModal(p12); setDescriptionText("Altered Landscape || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p12} /></Painting>
      </Year>
      <Year yr="2015">
        <Painting txt="Falling" narrow flag="sold" onClick={()=>{openModal(p13); setDescriptionText("Falling || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p13} /></Painting>
        <Painting txt="Grove" wide onClick={()=>{openModal(p14); setDescriptionText("Grove || Oil on canvas || dimensions"); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p14} /></Painting>
        <Painting txt="Mas Tequila" medium flag="sold" onClick={()=>{openModal(p15); setDescriptionText(`Mas Tequila || Oil on canvas || 36" x 36"`); }} closeFunc={()=>setShowModal(false)}><img alt="txt" src={p15} /></Painting>
      </Year>
      <Modal in={showModal}  desc={descriptionText} closebtn={<CloseModal onClick={()=>setShowModal(false)}>Close</CloseModal>}>
        <img src={selectedImg} />
      </Modal>
      </>
    );
  }



  export {Paintings};