//export const lightgrey = "#A8A7A8";
export const lightpink = "#CC527A"
export const pink = "#E8175D";
export const darkgrey = "#474747";
export const darkergrey = "#363636";
export const white = "#FFFFFF";
export const black = "#000000";

export const light = "#E5EEC1";
export const lightgreen = "#A2D4AB";
export const lightblue = "#3EACA8";
export const lightgrey = "#547A82";
export const grey = "#5A5050";